.html {
    scroll-behavior: smooth;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #fefefe;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/* 
LANDING PAGE STUFF
*/

.intro {
    display: table;
    position: relative;
    width: 100%;
    height: 80vh;
    padding: 0;
    background-color: black;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.intro h1 {
    font-family: 'Raleway', sans-serif;
    color: #fff;
    font-size: 6vmax;
    font-weight: 200;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 10px;
}

.intro h1 span {
    font-weight: 800;
    color: #5ca9fb;
}

.intro h2 {
    font-family: 'Raleway', sans-serif;
    color: #fff;
    font-size: 50px;
    font-weight: 200;
    text-align: center;
    /* text-transform: uppercase; */
    margin-top: 0;
    margin-bottom: 10px;
}

.intro p {
    color: #fff;
    font-size: 15px;
    font-weight: 300;
    line-height: 30px;
    margin: 0 auto;
    margin-bottom: 60px;
}

.super-gremlin {
    padding-top: 50;
    height: 40vh;
    justify-content: center;
}

.super-gremlin video {
    max-height: 30vh;
    max-width: 100%;
    display: block;
    margin: auto;
}

header .intro-text {
    padding-top: 0px;
    padding-bottom: 200px;
    text-align: center;
}


/* 
faq POLICY
*/

.faq {
    font-family: 'Raleway', sans-serif;
    color: #fff;
    display: table;
    position: relative;
    width: 100%;
    height: 80vh;
    background-color: black;
    justify-content: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    overflow: hidden;
}

.faq .margin {
    margin: 20px;
}

.faq h1 {
    font-size: calc(20px + 2vmin);
    letter-spacing: 3;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 20px;
}

.faq h2 {
    font-size: calc(15px + 0.5vmin);
    text-align: center;
    font-weight: 200;
    color: #fff;
    margin-top: 20px;
    margin-left: 20px;
}

.faq h3 {
    font-size: calc(15px + 0.5vmin);
    font-weight: 400;
    color: #fff;
    margin-top: 50px;
    margin-left: 20px;
    text-decoration: underline;
}

.faq h4 {
    font-family: 'Raleway', sans-serif;
    color: #fff;
    font-size: calc(14px + 2vmin);
    font-weight: 200;
    text-align: left;
    /* text-transform: uppercase; */
    margin: 20px 20px 20px 20px;
}

.faq h6 {
    font-size: calc(14px + 1.5vmin);
    font-weight: 300;
    text-align: left;
    margin: 50px 20px 20px 20px;
}

.faq p {
    font-size: calc(12px + 0.5vmin);
    font-weight: 300;
    text-align: left;
    margin: 20px 10px 10px 20px;
    /* text-transform: uppercase; */
}

.faq #one {
    font-family: 'Raleway', sans-serif;
    color: #fff;
    width: 60vw;
    justify-content: center;
    margin: auto;
}

.faq .indent {
    margin-left: calc(40px + 2vw);
}

.indent li {
    font-size: calc(10px + 1vmin);
    font-weight: 300;
    text-align: left;
    margin-left: calc(40px + 3vw);
    margin-top: 10px;
}

.address p {
    font-size: calc(12px + 1vmin);
    font-weight: 300;
    text-align: left;
    margin-left: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.faq #logo {
    width: 50vw;
}


/* 
FOOTER STUFF
*/

#footer {
    width: 100vw;
    height: 20vh;
    background-color: black;
}

.faq-service {
    width: 100vw;
    height: 10vh;
    display: table;
}

.faq-pol {
    width: 100vw;
    height: 10vh;
    display: table-row;
}

.privacy-pol {
    width: 100vw;
    height: 10vh;
    display: flex;
    justify-content: center;
}

.celll {
    padding-top: 60;
    padding-right: 10;
    height: 10vh;
    display: table-cell;
    text-align: right;
    color: #fafafa;
}

.celldivider {
    width: 2vw;
    height: 10vh;
    padding-top: 60;
    display: table-cell;
    text-align: center;
    color: #fafafa;
}

.cellc {
    height: 10vh;
    padding-top: 60;
    padding-left: 10;
    display: table-cell;
    text-align: left;
    color: #fafafa;
}

.cellr {
    height: 10vh;
    padding-top: 60;
    padding-left: 10;
    display: table-cell;
    text-align: left;
    color: #fafafa;
}

.copyright {
    text-align: center;
    padding-top: 20;
}

#footer a {
    color: white;
}

#footer a:hover {
    color: white;
    text-decoration: underline;
    text-decoration-color: white;
}

.footer p {
    color: white;
    font-size: 14px;
}

.celll a:hover {
    color: white;
    text-decoration: none;
}

.countdown {
    background-color: black;
    width: 100vw;
    padding: i 40vw 40vw 0;
    font-family: 'Raleway', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: 200;
    justify-content: center;
    text-align: center;
}

.countdown Countdown {
    display: block;
    margin: auto;
}


/* 
AboutFine stuff
*/

.content {
    overflow: hidden;
}

.about {
    min-height: 100vh;
    background-color: black;
}

.about h1 {
    font-family: 'Raleway', sans-serif;
    color: #fff;
    font-size: 6vmax;
    font-weight: 200;
    text-transform: uppercase;
    margin-top: 10vh;
    margin-bottom: 10px;
}

.about h2 {
    font-family: 'Raleway', sans-serif;
    color: white;
    font-weight: 300;
    margin-top: 5vh;
    font-size: calc(14px + 3vmin);
}

.about a {
    text-decoration: none;
}

.about h3 {
    color: white;
    font-size: calc(14px + 2vmin);
    font-weight: 500;
}

.about h4 {
    color: white;
    font-size: calc(14px + 1vmin);
    font-weight: 400;
}

.about h5 {
    color: white;
    font-size: calc(12px + 1vmin) !important;
    font-weight: 200;
}

.whiteLogo {
    text-align: left;
    justify-content: left;
}

.whiteLogo img {
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    max-height: 30vh;
    max-width: 200px;
}

.bgimage {
    overflow: hidden;
    background-color: black;
    background: url('./assets/aboutBackground.png');
    height: 89vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.about .prem-title {
    padding-top: 5vh;
}

.link-text {
    color: white;
    font-size: calc(10px + 1.5vmin);
    font-weight: 200;
    padding-left: 20;
}

.about-us h5 {
    color: white;
    font-size: calc(14px + 1vmin);
    text-transform: none;
    font-weight: 200;
}

.about-us {
    /* ? */
    display: block;
    /* overflow:auto; */
    text-align: center;
    padding-left: 10;
    padding-right: 10;
    padding-bottom: 50px;
    color: white;
    font-size: calc(10px + 1vmin);
    text-transform: none;
    font-weight: 200;
}

.about-us .card {
    background-color: transparent;
    opacity: 0.9;
    height: 300px;
}

.card-body {
    padding: 3rem 1rem;
}

.about-item {
    height: 200px;
    width: 90vw;
    text-align: center;
    margin-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
}

#fine-screen {
    height: 80vh;
    padding-top: 100px;
}

#fine-screen img {
    height: 70vh;
    /* width: 75vw; */
}


/* Mobile */

@media only screen and (max-width: 600px) {
    .about-item {
        justify-content: center;
        height: max-content;
        width: 100vw;
        text-align: center;
        /* margin-bottom: 20px; */
        padding-left: 20px;
        padding-right: 20px;
    }
    .about-item .col-12 {
        margin-bottom: 50px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .about-item img {
        /* display: none !important; */
        height: 100px;
    }
    .about-us {
        /* min-height: 100vh; */
        text-align: center !important;
        padding-left: 10;
        padding-right: 10;
        color: white;
        font-size: calc(10px + 1vmin);
        text-transform: none;
        font-weight: 200;
    }
    #fine-screen {
        height: auto;
        padding-top: 20px !important;
    }
    #fine-screen img {
        height: auto;
        width: 93vw !important;
    }
    .accordions {
        display: none !important;
    }
    .products {
        display: none !important;
    }
    #appstore {
        display: block !important;
        margin: auto;
        padding-top: none;
    }
    #header .intro-text {
        padding-bottom: 20px !important;
    }
    .navbar-brand>img {
        margin: 5px;
        height: 30px;
    }
    #app {
        margin-top: 60px;
        height: 30 !important;
    }
    #play {
        justify-content: end;
        margin-top: 60px;
        height: 30 !important;
    }
}

#appstore {
    margin: auto;
    display: block;
    padding-top: 30;
}

#app {
    /* margin: auto;
    display: block; */
    height: 50;
}

#play {
    justify-content: end;
    /* margin: auto;
    display: block; */
    height: 50;
}

.about-item img {
    display: flex;
    height: 250px;
}

.about-item .col {
    margin-left: 20px;
    margin-right: 20px;
    /* background-color: grey; */
    /* width: 100px !important; */
}

#fine-icon {
    height: 2.5em !important;
    margin: auto;
}

.products {
    /* ? */
    display: block;
    /* overflow:auto; */
    text-align: center;
    padding-bottom: 50px;
    padding-left: 10;
    padding-right: 10;
    padding-bottom: 50px;
    color: white;
    font-size: calc(10px + 1vmin);
    text-transform: none;
    font-weight: 200;
}

.products h2 {
    font-size: 30;
}

.table-dark {
    border-color: white !important;
}

.accordion h2 {
    margin-top: 0;
}

.accordion-header button {
    display: block;
    text-align: center;
}

.accordion-button {
    font-size: 20;
    color: white;
    background-color: black;
}

.accordions {
    width: 50vw;
    padding-bottom: 50px;
}

.accordion-body {
    padding: 1rem;
    color: white;
    font-size: 15 !important;
    background: #28282d;
}

.contact-us {
    padding-top: 50px;
    height: 30vh;
    text-align: center;
    display: block;
}

.contact-us h2 {
    font-size: 30;
}

.contact-us a {
    font-size: 20;
    color: white;
    text-decoration: underline;
}


/* CSS */

.button-86 {
    text-align: center;
    font-family: 'Raleway', sans-serif;
    all: unset;
    width: 100px;
    height: 30px;
    font-size: 16px;
    background: transparent;
    border: none;
    position: relative;
    color: #f0f0f0;
    cursor: pointer;
    z-index: 1;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin: auto;
    margin-top: 10vh;
}

.button-86::after,
.button-86::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -99999;
    transition: all .4s;
}

.button-86::before {
    transform: translate(0%, 0%);
    width: 100%;
    height: 100%;
    background: #28282d;
    border-radius: 10px;
}

.button-86::after {
    transform: translate(10px, 10px);
    width: 35px;
    height: 35px;
    background: #ffffff15;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 50px;
}

.button-86:hover::before {
    transform: translate(5%, 20%);
    width: 110%;
    height: 110%;
}

.button-86:hover::after {
    border-radius: 10px;
    transform: translate(0, 0);
    width: 100%;
    height: 100%;
}

.button-86:active::after {
    transition: 0s;
    transform: translate(0, 5%);
}

/* verify paypal page */
.verifyPayPal {
    height: 100vh;
    /* background-color: #28282d; */
}
.verifyPayPal h1 {
    font-family: 'Raleway', sans-serif;
    color: #000000;
    font-size: 6vmax;
    font-weight: 200;
    text-transform: uppercase;
    margin-top: 20vh;
    margin-bottom: 10px;
}
.verifyPayPal h2 {
    font-family: 'Raleway', sans-serif;
    color: black;
    font-weight: 300;
    margin-top: 1vh;
    font-size: calc(10px + 2vmin);
    margin-bottom: 10vh;
}

.verifyPayPal img {
    height: 35;
    margin-top: 2vh;
}
.spinner.loading {
    padding: 50px;
    position: relative;
    text-align: center;
  }
  
  .spinner.loading:before {
    content: "";
    height: 40px;
    width: 40px;
    margin: -15px auto auto -15px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-width: 6px;
    border-style: solid;
    border-color: #3b7bbf  #ccc #ccc;
    border-radius: 100%;
    animation: rotation .7s infinite linear;
  }
  
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    } to {
      transform: rotate(359deg);
    }
  }
